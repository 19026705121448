<template>
  <div>
    <!-- <b-modal v-model="isShowModal.editTemplateName" title="Edit Template Name" hide-footer> -->
    <el-dialog top="10vh" title="Edit Template Name" @close="isShowModal.editTemplateName = false" :visible.sync="isShowModal.editTemplateName" :close-on-press-escape="false" :close-on-click-modal="false">
      <b-form @submit.prevent="onSubmitEditTemplateName" @reset="closeModalEditTemplate">
        <el-input
          type="text"
          placeholder="Input your message template name"
          v-model="temp.template_name"
          ref="inputEditTemplateName"
          @keydown.native="handlerInputTemplateName($event, 'edit_template_name')"
          @keyup.native="handlerInputTemplateName($event, 'edit_template_name')"
          maxlength="512"
          show-word-limit
        />
        <div class="d-flex flex-row-reverse mt-2">
          <el-button @click="submitChangeTemplateName" :disabled="!temp.template_name" size="small" type="primary" class="ml-2">{{ $t('general.done') }}</el-button>
          <el-button @click="isShowModal.editTemplateName = false" size="small" type="secondary">{{ $t('general.cancel') }}</el-button>
        </div>
      </b-form>
    </el-dialog>
    <!-- </b-modal> -->
    <!-- <b-modal v-model="isShowModal.addSample" title="Add Sample Content" hide-footer> -->
    <el-dialog top="10vh" title="Add Sample Content" @close="isShowModal.addSample = false" :visible.sync="isShowModal.addSample" :close-on-press-escape="false" :close-on-click-modal="false">
      <p>
        To help us understand what kind of message you want to send, you have the option to provide specific content examples for your template. You can add a sample template for one or all languages you are submitting. Make sure not to include any actual user or customer information, and provide only sample content in your examples.
      </p>
      <hr/>
      <div v-if="headerHasParamOrMedia">
        <h6 class="text-capitalize">Header</h6>
        <el-input v-if="example_template.header.type == 'text'" class="mt-1" size="small" v-model="example_template.header.example.data" :placeholder="'Input content for {{1}}'" />
        <b-file v-loading="loading.upload_sample_media" class="mt-2" v-else-if="example_template.header.content && example_template.header.type == 'media'" :accept="whiteListExtensionHeaderMedia[example_template.header.content].join(',')" @input="handlerFileHeaderMedia" v-model="example_template.header.file"/>
        <hr/>
      </div>
      <div v-if="bodyHasParam">
        <h6 class="text-capitalize">Body</h6>
        <div v-if="example_template.body.examples.length > 0">
          <span v-for="(item, i) in example_template.body.examples" :key="i">
            <el-input class="mt-1" size="small" v-model="example_template.body.examples[i].text" :placeholder="`Input content for ` + item.replacement"/>
          </span>
        </div>
        <hr/>
      </div>
      <div v-if="buttonHasDynamic && example_template.buttons.content.length">
        <h6 class="text-capitalize">Buttons</h6>
        <span v-for="(item, i) in example_template.buttons.content" :key="i">
          <el-input
            size="small"
            class="mt-1"
            type="text"
            v-model="example_template.buttons.examples[i]"
            v-if="item.type == 'url' && item.url_type == 'dynamic'"
            :placeholder="`Input full URL for ` + item.url" />
        </span>
      </div>
      <div class="d-flex flex-row-reverse mt-4">
        <el-button @click="submitExample" :disabled="isInvalidExample" type="primary" size="small" class="ml-2">{{ $t('general.done') }}</el-button>
        <el-button @click="isShowModal.addSample = false" size="small" plain>{{ $t('general.cancel') }}</el-button>
      </div>
    </el-dialog>
    <!-- </b-modal> -->
    <!-- Modal Carousel -->
    <PreviewCarousel
      :show="isShowModal.carouselCard"
      :active_template_show="active_template.carousel[active_card]"
      :language_str="active_template.language_str"
      :card_index="active_card"
      @onHide="isShowModal.carouselCard = false"
    />
    <b-card-body v-if="step === 1">
      <b-card-title class="text-capitalize">Template Category</b-card-title>
      <b-card-sub-title class="mb-4">
        Choose what type of message template you want to create.
      </b-card-sub-title>
      <b-row>
        <b-col sm="12" md="12" v-for="(item, i) in template_category.whatsapp" :key="i" class="mb-2">
          <div class="list-group">
            <a @click="setSelectedCategory(item)" class="list-group-item list-group-item-action cursor-pointer" :class="{ active: item.key == form1.category }">
              <font-awesome :icon="item.icon"/>&nbsp; {{ item.name }}
              <br/>{{ item.info }}
              <!-- <el-popover
                placement="right"
                width="300"
                trigger="hover">
                <p style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">{{ item.info }}</p>
                <span slot="reference" class="float-right"><font-awesome icon="info-circle"/></span>
              </el-popover> -->
            </a>
          </div>
        </b-col>
      </b-row>
      <hr/>
      <b-card-title class="text-capitalize">Name</b-card-title>
      <b-card-sub-title class="mb-4">
        Give your message template a name.
      </b-card-sub-title>
      <b-row>
        <b-col>
          <el-input
            type="text"
            placeholder="Input your message template name"
            v-model="form1.template_name"
            maxlength="512"
            @keydown.native="handlerInputTemplateName($event, 'create_template_name')"
            @keyup.native="handlerInputTemplateName($event, 'create_template_name')"
            show-word-limit
          />
        </b-col>
      </b-row>
      <hr/>
      <b-card-title class="text-capitalize">Language</b-card-title>
      <b-card-sub-title class="mb-4">
        Choose which languages your message template will be sent in. You can delete or add more languages later.
      </b-card-sub-title>
      <b-row>
        <b-col>
          <el-select v-model="temp.selected_language" filterable multiple placeholder="Select languages" class="w-100">
            <el-option
              v-for="item in languages_list"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </b-col>
      </b-row>
      <hr/>
      <b-card-title class="text-capitalize">Divisions</b-card-title>
      <b-card-sub-title class="mb-4">
        Choose which divisions are eligible to use the template.
      </b-card-sub-title>
      <b-row>
        <b-col>
          <el-select v-model="temp.groups_id" filterable multiple placeholder="Select divisions" class="w-100">
            <el-option
              v-for="item in division_list"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </b-col>
      </b-row>
      <hr/>
      <div class="d-flex float-right mb-4">
        <el-button @click="nextStep(step)" :disabled="!isValidStep1" size="small" type="primary">Continue</el-button>
        <el-button @click="$router.back()" type="default" size="small">Back</el-button>
      </div>
    </b-card-body>
    <b-card-body v-if="step === 2">
      <div class="inline inline-flex justify-between-content">
        <b-row>
          <b-col md=9>
            <h5 class="text-lowercase w-50">{{ form1.template_name }}&nbsp;&nbsp;<a @click="showModalEditTemplateName" class="cursor-pointer"><font-awesome icon="pen"/></a></h5>
          </b-col>
          <b-col md=3 class="float-right">
            <el-button type="primary" :disabled="checkComplete()" :loading="loading.submit" @click="submitTemplate" size="small" class="float-right ml-2">Submit</el-button>
            <el-button size="small" plain @click="$router.back()" class="float-right">Cancel</el-button>
          </b-col>
        </b-row>
      </div>
      <hr/>
      <b-row>
        <b-col md=2>
          <b-row>
            <b-col sm="12" md="12">
              <b-card-title class="text-capitalize">Languages</b-card-title>
            </b-col>
            <b-col sm="12" md="12" v-for="(item, i) in temp.selected_language" :key="i" class="mb-2">
              <div class="list-group">
                <a @click="setActiveTemplate(item)" class="list-group-item list-group-item-action cursor-pointer" :class="{ active: item == active_template.language }">
                  {{ languages_list.find((v) => v.value === item).label }}
                </a>
              </div>
            </b-col>
            <b-col sm="12" md="12">
              <el-select @input="handlerAddMoreLanguage" v-model="addMoreLanguage" filterable placeholder="Add language" class="w-100">
                <el-option
                  v-for="item in languages_list.filter((v) => !temp.selected_language.includes(v.value))"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </b-col>
          </b-row>
        </b-col>
        <b-col md=7>
          <div class="inline inline-flex justify-between-content">
            <b-row>
              <b-col md=6>
                <h5 class="text-capitalize">{{ languages_list.find((v) => v.value === active_template.language).label }} Message Content <a v-if="template_form.length > 1" @click="handlerRemoveLanguageContent(active_template)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1em" icon="trash"/></a>&nbsp;</h5>
              </b-col>
              <b-col md=6 class="float-right">
                <el-button :disabled="!isNeedSample" @click="addSampleContent(active_template)" size="small" type="info" class="float-right ml-2">Add Sample</el-button>
                <span v-if="active_template.body.content">
                  <span class="float-right" v-if="isCompleteSample"><font-awesome icon="circle" class="text-success"/>&nbsp;Good</span>
                  <span class="float-right" v-else><font-awesome :icon="['fas', 'circle']" class="text-danger"/>&nbsp;Incomplete sample</span>
                </span>
              </b-col>
            </b-row>
          </div>
          <hr/>
          <template v-if="form1.category != 'authentication'">
            <div id="header-content">
              <b-card-title class="text-capitalize">Header (optional)</b-card-title>
              <b-card-sub-title class="mb-2">Add a title or choose which type of media you'll use for this header.</b-card-sub-title>
              <b-row>
                <b-col md=3>
                  <el-select @change="handlerChangeHeaderType" v-model="active_template.header.type">
                    <el-option v-for="(item, i) in header_category.filter((v1) => (active_template.category !== 'marketing') ? v1.type !== 'carousel' : v1.type)" :key="i" :label="item.label" :value="item.type"/>
                  </el-select>
                </b-col>
                <b-col>
                  <el-input v-if="active_template.header.type === 'text'"
                    :placeholder="'Input text in ' + active_template.language_str"
                    v-model="active_template.header.content"
                    @keydown.native="handlerInputHeaderContent"
                    ref="inputHeaderContent"
                    maxlength="60"
                    show-word-limit />
                  <div v-if="active_template.header.type === 'carousel'">
                    <el-input type="textarea"
                      :placeholder="'Input text in ' + active_template.language_str"
                      v-model="active_template.body.content"
                      maxlength="1024"
                      ref="inputBodyContent"
                      @focus="() => last_index_emoji = null"
                      @keydown.native="handlerInputBodyContent"
                      show-word-limit
                      rows="6"/>
                    <div class="float-right mt-2 mb-2">
                      <el-popover
                        placement="top"
                        width="380"
                        trigger="hover"
                      >
                        <picker @select="addEmoji" size="16" :showPreview="false" />
                        <span slot="reference">
                          <FeatherIcon type="smile" class="mr-2" style="width: 16px; margin-top: 5px; margin-bottom: -5px;"/>
                        </span>
                      </el-popover>
                      <el-button size="small" type="info"
                        @click="addVariableBody"><font-awesome icon="plus"/>
                        &nbsp; Add Variable
                      </el-button>&nbsp;
                      <el-popover
                        placement="right"
                        width="320"
                        trigger="hover">
                        <p style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">This is text you specify in the API that will be personalized to the customer, such as their name or invoice number.</p>
                        <span slot="reference"><font-awesome icon="info-circle"/></span>
                      </el-popover>
                    </div>
                    <div class="float-left mt-2 mb-2" v-if="this.active_template.carousel.length < 10">
                      <el-button size="small" type="info" @click="addCarouselCard"><font-awesome icon="plus"/>
                        &nbsp; Add Card
                      </el-button>
                    </div>
                  </div>
                  <div class="float-right mt-2 mb-2" v-if="active_template.header.type === 'text'">
                    <el-button size="small" type="info"
                      :disabled="isMaxVariableHeader"
                      @click="addVariableHeader"><font-awesome icon="plus"/>
                      &nbsp; Add Variable
                    </el-button>&nbsp;
                    <el-popover
                      placement="right"
                      width="320"
                      trigger="hover">
                      <p style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">This is text you specify in the API that will be personalized to the customer, such as their name or invoice number.</p>
                      <span slot="reference"><font-awesome icon="info-circle"/></span>
                    </el-popover>
                  </div>
                  <el-radio-group v-model="active_template.header.content" v-if="active_template.header.type === 'media'">
                    <el-radio-button border v-for="item in header_media_type" :key="item.type" :label="item.type">
                      <font-awesome :icon="item.icon"/>&nbsp; {{ item.label }}
                    </el-radio-button>
                  </el-radio-group>
                </b-col>
              </b-row>
            </div>
            <hr/>
            <div v-if="active_template.header.type !== 'carousel'">
              <div id="body-content">
                <div class="mb-5">
                  <b-card-title class="text-capitalize">Body</b-card-title>
                  <b-card-sub-title class="mb-2">Enter the text for your message in the language you've selected.</b-card-sub-title>
                  <el-input type="textarea"
                    :placeholder="'Input text in ' + active_template.language_str"
                    v-model="active_template.body.content"
                    maxlength="1024"
                    ref="inputBodyContent"
                    @focus="() => last_index_emoji = null"
                    @keydown.native="handlerInputBodyContent"
                    show-word-limit
                    rows="6"/>
                  <div class="float-right mt-2 mb-2">
                    <el-popover
                      placement="top"
                      width="380"
                      trigger="hover"
                    >
                      <picker @select="addEmoji" size="16" :showPreview="false" />
                      <span slot="reference">
                        <FeatherIcon type="smile" class="mr-2" style="width: 16px; margin-top: 5px; margin-bottom: -5px;"/>
                      </span>
                    </el-popover>
                    <el-button size="small" type="info"
                      @click="addVariableBody"><font-awesome icon="plus"/>
                      &nbsp; Add Variable
                    </el-button>&nbsp;
                    <el-popover
                      placement="right"
                      width="320"
                      trigger="hover">
                      <p style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">This is text you specify in the API that will be personalized to the customer, such as their name or invoice number.</p>
                      <span slot="reference"><font-awesome icon="info-circle"/></span>
                    </el-popover>
                  </div>
                </div>
              </div>
              <hr/>
              <div id="footer-content">
                <b-card-title class="text-capitalize">Footer (optional)</b-card-title>
                <b-card-sub-title class="mb-2">Add a short line of text to the bottom of your message template.</b-card-sub-title>
                <el-input
                  type="text"
                  :placeholder="'Input text in ' + active_template.language_str"
                  v-model="active_template.footer.content"
                  maxlength="60"
                  show-word-limit
                />
              </div>
              <hr/>
              <div id="buttons-content">
                <b-card-title class="text-capitalize">Buttons (optional)</b-card-title>
                <b-card-sub-title class="mb-2">Create buttons that let customers respond to your message or take action.</b-card-sub-title>
                <el-select @change="handlerChangeButtonsParent" v-model="active_template.buttons.parent_type">
                  <el-option v-for="(item, i) in footer_category" :key="i" :label="item.label" :value="item.type"
                    :disabled="checkContent(item) && item.type != 'none'"
                  />
                </el-select>
                <div v-if="active_template.buttons.parent_type !== 'none'">
                  <div v-if="active_template.buttons.content.filter((v) => v.type === 'quick_reply').length > 0" class="mt-2">
                    <b-card>
                      <b-card-title class="text-capitalize">Quick Reply</b-card-title>
                      <b-row>
                        <b-col md=10
                          v-for="(item, i) in active_template.buttons.content.filter((v) => v.type === 'quick_reply')" :key="i">
                          <b-form-group
                            label="Text Button">
                            <el-input v-model="item.text"
                              class="w-80 mr-2"
                              maxlength="20"
                              show-word-limit/>
                            <span>
                              <a @click="handlerRemoveQuickReply(active_template.buttons.content.findIndex((v) => v.type === 'quick_reply' && v.text === item.text))" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1em" icon="times-circle"/></a>
                            </span>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <el-button
                        @click="addButtonQuickReply"
                        :disabled="active_template.buttons.content.length >= max_length.all"
                        type="info"
                        size="small"
                        class="mt-2"><font-awesome icon="plus"/>&nbsp; Add Another Button
                      </el-button>
                    </b-card>
                  </div>
                  <div v-if="active_template.buttons.content.filter((v) => v.type !== 'quick_reply').length > 0" class="mt-2">
                    <b-card class="mt-3 table-responsive">
                      <b-card-title class="text-capitalize">Call To Action</b-card-title>
                      <div
                        v-for="(item, i) in active_template.buttons.content.filter((v) => v.type !== 'quick_reply')" :key="i">
                        <b-row>
                          <b-col md=3>
                            <b-form-group
                              label="Type of Action">
                              <el-select v-model="item.type">
                                <el-option
                                  v-for="item_call in call_to_action_category"
                                  :disabled="item_call.type !== item.type && active_template.buttons.content.filter((x) => x.type == item_call.type).length === item_call.max_length"
                                  :key="item_call.type"
                                  :value="item_call.type"
                                  :label="item_call.label"/>
                              </el-select>
                            </b-form-group>
                          </b-col>
                          <b-col md="5">
                            <b-form-group
                              :label="['copy_code'].includes(item.type) ? 'Coupon Code' : 'Button Text'">
                              <el-input v-model="item.text"
                                :maxlength="text_length[item.type]"
                                :class="{ 'w-80': ['copy_code'].includes(item.type) }"
                                show-word-limit/>
                              <span v-if="['copy_code'].includes(item.type)">
                                &nbsp;<a @click="handlerRemoveCTA(active_template.buttons.content.findIndex((v) => v.type === item.type && v.text === item.text))" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1em" icon="times-circle"/></a>
                              </span>
                            </b-form-group>
                          </b-col>
                          <!-- Flow Button -->
                          <b-col md=4 v-if="item.type === 'flow'">
                            <b-form-group
                              label="Choose Flow" style="width: calc(250%)">
                              <el-select
                                class="w-80"
                                v-model="flowID"
                                filterable remote reserve-keyword clearable
                                placeholder="Please enter a name of flow"
                                @change="handlerDetailFlow"
                                :remote-method="handlerSearchFlow"
                                :loading="loading.get_flows">
                                <el-option
                                  v-for="item_flow in flowList"
                                  :key="item_flow.id"
                                  :label="item_flow.name"
                                  :value="item_flow.id">
                                </el-option>
                              </el-select>
                              <span>
                                &nbsp;<a @click="handlerRemoveCTA(active_template.buttons.content.findIndex((v) => v.type === item.type && v.text === item.text))" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1em" icon="times-circle"/></a>
                              </span>
                            </b-form-group>
                          </b-col>
                          <!-- Website Button -->
                          <b-col md=3 v-if="item.type === 'url'">
                            <b-form-group
                              label="URL Type">
                              <el-select v-model="item.url_type">
                                <el-option v-for="item_type in url_type" :key="item_type.type" :value="item_type.type" :label="item_type.label"/>
                              </el-select>
                            </b-form-group>
                          </b-col>
                          <b-col md=1 v-if="item.type === 'url'">
                            <b-form-group
                              label="Website URL" style="width: calc(2000%)">
                              <el-input v-model="item.url"
                                maxlength="2000"
                                class="w-80"
                                show-word-limit
                                >
                                <!-- :style="{ 'border-color:red': !checkUrl(i) }" -->
                                <template v-if="item.url_type == 'dynamic'" slot="append">
                                  <span v-pre>{{1}}</span>
                                </template>
                              </el-input>
                              <span>
                                &nbsp;<a @click="handlerRemoveCTA(active_template.buttons.content.findIndex((v) => v.type === item.type && v.text === item.text))" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1em" icon="times-circle"/></a>
                              </span>
                            </b-form-group>
                          </b-col>
                          <!-- End Of Website Button -->
                          <!-- Phone Number Button -->
                          <b-col md=3 v-if="item.type === 'phone_number'">
                            <b-form-group
                              label="Country">
                              <el-select v-model="item.country_code">
                                <el-option v-for="item in country_code" :key="item.value" :value="item.value" :label="item.label_str"/>
                              </el-select>
                            </b-form-group>
                          </b-col>
                          <b-col md=1 v-if="item.type === 'phone_number'">
                            <b-form-group style="width: calc(1000%)">
                              <span slot="label">Phone Number&nbsp; <el-popover
                                  placement="right"
                                  width="200"
                                  trigger="hover">
                                  <p style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">Input phone number<br/>without country code.</p>
                                  <span slot="reference"><font-awesome icon="info-circle"/></span>
                                </el-popover>
                              </span>
                              <el-input v-model="item.phone_number"
                                maxlength="20"
                                class="w-80"
                                show-word-limit/>
                              <span>
                                &nbsp;<a @click="handlerRemoveCTA(active_template.buttons.content.findIndex((v) => v.type === item.type && v.text === item.text))" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1em" icon="times-circle"/></a>
                              </span>
                            </b-form-group>
                          </b-col>
                          <!-- End Of Phone Number Button -->
                        </b-row>
                      </div>
                      <el-button
                        @click="addButtonCTA"
                        :disabled="active_template.buttons.content.filter((v) => v.type !== 'quick_reply').length >= max_length.cta || active_template.buttons.content.length >= max_length.all"
                        type="info"
                        size="small"
                        class="mt-2"><font-awesome icon="plus"/>&nbsp; Add Another Button
                      </el-button>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="card-content" v-for="(item_carousel, i) in active_template.carousel" :key="i">
                <div class="inline inline-flex justify-between-content">
                  <b-row>
                    <b-col md=3 @click="showCarouselCard(i)" class="cursor-pointer">
                        <h5 class="text-capitalize">Card {{ i+1 }} &nbsp;</h5>
                        <span>
                          <span class="float-left mt-1" v-if="isCompleteSampleCard(i)"><font-awesome icon="circle" class="text-success"/>&nbsp;Good</span>
                          <span class="float-left mt-1" v-else><font-awesome :icon="['fas', 'circle']" class="text-danger"/>&nbsp;Incomplete sample</span>
                        </span>
                    </b-col>
                    <b-col md=5 class="text-center">
                      <div class="header-media-area mb-2">
                        <span v-if="item_carousel.header.example.base64 && item_carousel.header.content === 'image'">
                          <div class="header-media-filled" :style="'background-image: url('+ item_carousel.header.example.base64 +')'"/>
                        </span>
                        <div v-else class="header-media-content" :style="'background-image: url('+ bg_media[item_carousel.header.content] +')'">
                        </div>
                      </div>
                    </b-col>
                    <b-col md=4 class="float-right">
                      <!-- <a class="cursor-pointer float-right ml-2"><font-awesome class="text-danger" style="font-size: 1em" icon="trash"/></a> -->
                      <el-button v-if="active_template.carousel.length > 1" @click="handlerRemoveCardContent(i)" size="small" type="warning" class="float-right ml-2 btn-danger"><font-awesome style="font-size: 1em" icon="trash"/></el-button>
                      <el-button @click="showCarouselCard(i)" size="small" type="warning" class="float-right ml-2 btn-primary"><font-awesome style="font-size: 1em" icon="edit"/></el-button>
                    </b-col>
                  </b-row>
                  <hr/>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div id="body-content">
              <div>
                <b-card-title class="text-capitalize">Code Delivery</b-card-title>
                <b-card-sub-title class="mb-2">Choose how customers send the code from WhatsApp to your app.</b-card-sub-title>
                <b-row>
                  <b-col sm="12" md="12" class="mb-2" v-for="(opt, iopt) in otp_delivery_options" :key="iopt">
                    <div class="list-group">
                      <a @click="handlerOtpTypeRadio(opt.value)" class="list-group-item list-group-item-action cursor-pointer" :class="{ active: opt.value == active_template.otp_delivery_type }">
                        {{ opt.title }}<br/>
                        <small>{{ opt.description }}</small>
                      </a>
                    </div>
                    <div v-if="active_template.otp_delivery_type == 'one_tap' && iopt === 0" class="card mt-2">
                      <div class="card-body">
                        <b-form-group label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label="Package Name">
                          <b-input
                            type="text"
                            placeholder="Input package name. Example: com.example.myapplication"
                            v-model="active_template.otp_autofill_text"
                          />
                        </b-form-group>
                        <b-form-group label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label="App signature hash">
                          <b-input
                            type="text"
                            placeholder="Input text"
                            v-model="active_template.otp_signature_hash"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
            <hr/>
            <div id="body-content">
              <div>
                <b-card-title class="text-capitalize">Button text</b-card-title>
                <b-card-sub-title class="mb-2">You can customise the button text for both auto-fill and copy code. Even when zero-tap is turned on, buttons are still needed for the backup code delivery method.</b-card-sub-title>
                <div>
                  <b-form-group label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Copy code">
                    <el-input
                      type="text"
                      size="small"
                      placeholder="Input button text"
                      v-model="active_template.buttons.content[0].text"
                      show-word-limit
                      maxlength="25"
                    />
                  </b-form-group>
                </div>
              </div>
            </div>
            <hr/>
            <div id="body-content">
              <div>
                <b-card-title class="text-capitalize">Message content</b-card-title>
                <b-card-sub-title class="mb-2">Content for authentication message templates can't be edited. You can add additional content from the options below.</b-card-sub-title>
                <div>
                  <el-checkbox @change="checkedSecurityChanged($event, active_template.language)" v-model="active_template.otp_add_security_wording">Add security recommendation</el-checkbox>
                </div>
                <div>
                  <el-checkbox @change="checkedExpiryChanged($event, active_template.language)" v-model="active_template.otp_add_expiry_wording">Add expiry time for the code</el-checkbox>
                  <b-form-group v-if="active_template.otp_add_expiry_wording" label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="4"
                    label="Expires in">
                    <el-input
                      type="number"
                      size="small"
                      @change="handlerChangeOtpExpires"
                      placeholder="Input button text"
                      v-model="active_template.otp_expires_in"
                    >
                      <template slot="append">minutes</template>
                    </el-input>
                    </b-form-group>
                </div>
              </div>
            </div>
          </template>
        </b-col>
        <b-col md=3>
          <div class="screen">
            <div class="screen-container">
              <div class="chat">
                <div class="chat-container">
                  <div class="conversation">
                    <div class="conversation-container vh-100">
                      <h6 class="mt-2">Preview</h6>
                      <!-- <div class="preview-message received w-100">
                        <span class="mb-2" v-if="previewHeaderContent && active_template.header.type === 'text'" style="font-size: 14px; font-weight: bold">{{previewHeaderContent}}<br/></span>
                        <div class="header-media-area mb-2" v-if="active_template.header.type === 'media' && active_template.header.content">
                          <span v-if="active_template.header.example.base64 && active_template.header.content === 'image'">
                            <div v-if="active_template.header.content === 'image'" class="header-media-filled" :style="'background-image: url('+ active_template.header.example.base64 +')'"/>
                          </span>
                          <div v-else class="header-media-content" :style="'background-image: url('+ bg_media[active_template.header.content] +')'">
                          </div>
                        </div>
                        <p class="preview-body" v-html="previewContent"/>
                        <p class="mt-2 preview-footer" v-if="previewFooterContent">{{previewFooterContent}}<br/></p>
                        <span class="metadata"><span class="time">{{ moment().format('HH:mm') }}</span></span>
                        <div class="cta-area w-100" v-if="active_template.buttons.parent_type === 'call_to_action'">
                          <div class="cta-button" v-for="(item, i) in active_template.buttons.content" :key="i">
                            <p>
                              <font-awesome :icon="item.type === 'url' ? 'external-link-alt' : (item.type === 'otp' ? 'copy' : 'phone-alt')"/>
                              &nbsp;{{ item.text }}</p>
                          </div>
                        </div>
                      </div> -->
                      <!-- <div class="button-area w-100" style="margin-top: -1em" v-if="active_template.buttons.parent_type === 'quick_reply'">
                        <div class="row-button">
                          <div class="col-button" v-for="(item, i) in active_template.buttons.content" :key="i">
                            {{ item.text }}
                          </div>
                        </div>
                      </div> -->
                      <div class="table-responsive preview-message received w-100" :style="active_template.header.type === 'carousel' ? 'margin-bottom: -8px;' : ''">
                        <span class="mb-2" v-if="previewHeaderContent && (active_template.header.type === 'text')" style="font-size: 14px; font-weight: bold">{{previewHeaderContent}}<br/></span>
                        <div class="header-media-area mb-2" v-if="active_template.header.type === 'media' && active_template.header.content">
                          <span v-if="active_template.header.example.base64 && active_template.header.content === 'image'">
                            <div v-if="active_template.header.content === 'image'" class="header-media-filled" :style="'background-image: url('+ active_template.header.example.base64 +')'"/>
                          </span>
                          <div v-else class="header-media-content" :style="'background-image: url('+ bg_media[active_template.header.content] +')'">
                          </div>
                        </div>
                        <div v-if="active_template.header.type !== 'carousel'">
                          <p class="preview-body" v-html="previewContent"/>
                          <p class="mt-2 preview-footer" v-if="previewFooterContent">{{previewFooterContent}}<br/></p>
                          <span class="metadata"><span class="time">{{ moment().format('HH:mm') }}</span></span>
                          <div class="cta-area w-100" v-if="active_template.buttons.content.length <= 3 && active_template.buttons.parent_type !== 'none'">
                            <div class="cta-button" v-for="(item, i) in active_template.buttons.content" :key="i">
                              <p>
                                <font-awesome :icon="button_icons[item.type]"/>
                                &nbsp;{{ item.type === 'copy_code' ? 'Copy Coupon Code' : item.text }}
                              </p>
                            </div>
                          </div>
                          <div class="cta-area w-100" v-else-if="active_template.buttons.parent_type !== 'none'">
                            <div class="cta-button" v-for="(item, i) in button_list" :key="i">
                              <p>
                                <font-awesome :icon="button_icons[item.type]"/>
                                &nbsp;{{ item.type === 'copy_code' ? 'Copy Coupon Code' : item.text }}
                              </p>
                            </div>
                            <div class="cta-button">
                              <a @click="showAllButtons()" class="cursor-pointer">
                                <font-awesome icon="list"/>
                                &nbsp;See all options
                              </a>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <p class="preview-body" v-html="previewContent"/>
                          <span class="metadata"><span class="time">{{ moment().format('HH:mm') }}</span></span>
                        </div>
                      </div>
                      <Transition name="fade">
                        <div class="preview-button bottom w-100" v-if="isShowModal.allButton">
                          <span style="font-size: 14px; line-height: 1.75rem;">
                            All Options
                            <a @click="showAllButtons()" class="float-right cursor-pointer text-black" style="font-size: 14px;">
                              <font-awesome icon="times"/>
                            </a>
                          </span>
                          <div class="cta-area w-100" v-if="active_template.buttons.content.filter((v) => v.type !== 'quick_reply').length > 0">
                            <div class="cta-button" v-for="(item, i) in active_template.buttons.content.filter((v) => v.type !== 'quick_reply')" :key="i">
                              <p>
                                <font-awesome :icon="button_icons[item.type]"/>
                                &nbsp;{{ item.type === 'copy_code' ? 'Copy Coupon Code' : item.text }}
                              </p>
                            </div>
                          </div>
                          <div class="cta-area w-100" v-if="active_template.buttons.content.filter((v) => v.type === 'quick_reply').length > 0">
                            <div class="cta-button" v-for="(item, i) in active_template.buttons.content.filter((v) => v.type === 'quick_reply')" :key="i">
                              <p>
                                <font-awesome :icon="button_icons[item.type]"/>
                                &nbsp;{{ item.type === 'copy_code' ? 'Copy Coupon Code' : item.text }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Transition>
                      <!-- carousel message -->
                      <!-- Swiper Carousel -->
                      <div v-if="active_template.header.type === 'carousel'">
                        <swiper
                          slides-per-view="1"
                          :scrollbar="{ draggable: true }"
                          :pagination="{
                            type: 'fraction',
                          }"
                          :spaceBetween="5"
                          mousewheel
                          freeMode
                          navigation
                        >
                          <swiper-slide v-for="(card, i) in 10" :key="i">
                            <div class="preview-message-carousel received" v-if="card <= active_template.carousel.length">
                              <div class="header-media-area mb-2">
                                <span v-if="active_template?.carousel[i]?.header.example.base64 && active_template?.carousel[i]?.header.content === 'image'">
                                  <div class="header-media-filled" :style="'background-image: url('+ active_template?.carousel[i]?.header.example.base64 +')'"/>
                                </span>
                                <div v-else class="header-media-content" :style="'background-image: url('+ bg_media[active_template?.carousel[i]?.header.content] +')'">
                                </div>
                              </div>
                              <p class="preview-body" v-html="previewContentCarousel(i)"/>
                              <p class="mt-2 preview-footer" v-if="previewFooterContentCarousel(i)">{{previewFooterContentCarousel(i)}}<br/></p>
                              <span class="metadata"><span class="time">{{ moment().format('HH:mm') }}</span></span>
                              <div class="cta-area w-100" v-if="active_template?.carousel[i]?.buttons.parent_type === 'call_to_action'">
                                <div class="cta-button" v-for="(item, x) in active_template?.carousel[i]?.buttons.content" :key="x">
                                  <p>
                                    <font-awesome :icon="button_icons[item.type]"/>
                                    &nbsp;{{ item.text }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="button-area w-100" style="margin-top: -1em" v-if="active_template?.carousel[i]?.buttons.parent_type === 'quick_reply'">
                              <div class="row-button">
                                <div class="col-button" v-for="(item, x) in active_template?.carousel[i]?.buttons.content" :key="x">
                                  {{ item.text }}
                                </div>
                              </div>
                            </div> -->
                          </swiper-slide>
                          <div class="swiper-scrollbar" slot="scrollbar"></div>
                          <div class="swiper-button-prev" slot="button-prev"></div>
                          <div class="swiper-button-next" slot="button-next"></div>
                        </swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>
<script>
import moment from 'moment';
import {
  cloneDeep, isEmpty, escapeRegExp, uniq,
} from 'lodash';
import language from '@/library/language.json';
import country_code from '@/library/country_code.json';
import popupErrorMessages from '@/library/popup-error-messages';
import { Picker } from 'emoji-mart-vue-2';
import wa_auth_wording from '@/library/wa_auth_wording.json';
// import { Swiper, SwiperSlide } from 'swiper-vue2';
import { Scrollbar, Mousewheel, Navigation } from 'swiper';
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2';
// import 'swiper/swiper-bundle.css';
import notifApi from '../../../api/notifications';
import base64File from '../../../library/base64File';
import divisions from '../../../api/divisions';
import PreviewCarousel from './Carousel.vue';
import flowsAPI from '../../../api/flows';

import 'swiper/swiper-bundle.css';

SwiperCore.use([Scrollbar, Mousewheel, Navigation]);
export default {
  name: 'CreateWhatsappTemplate',
  components: {
    Picker,
    PreviewCarousel,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      moment,
      active_card: 0,
      step: 1,
      loading: {
        upload_sample_media: false,
        submit: false,
        get_flows: false,
      },
      isShowModal: {
        editTemplateName: false,
        addSample: false,
        carouselCard: false,
        allButton: false,
      },
      text_length: {
        url: 25,
        phone_number: 25,
        quick_reply: 20,
        copy_code: 15,
      },
      max_length: {
        all: 10,
        cta: 5,
      },
      button_icons: {
        quick_reply: 'reply',
        copy_code: 'copy',
        url: 'external-link-alt',
        otp: 'copy',
        phone_number: 'phone-alt',
        flow: 'edit',
      },
      temp: {
        selected_language: ['id'],
        template_name: '',
        groups_id: [],
      },
      template_category: {
        whatsapp: [
          {
            name: 'Marketing',
            key: 'marketing',
            icon: 'bullhorn',
            info: 'Send promotions or information about your products, services, or business.',
          },
          {
            name: 'Utility',
            key: 'utility',
            icon: 'bell',
            info: 'Send messages about an existing order or account.',
          },
          {
            name: 'Authentication',
            key: 'authentication',
            icon: 'key',
            info: 'Send codes to verify a transaction or login.',
          },
        ],
      },
      bg_media: {
        image: 'https://media.wappin.chat/template-header-image.png',
        document: 'https://media.wappin.chat/template-header-document.png',
        video: 'https://media.wappin.chat/template-header-video.png',
      },
      form1: {
        category: '',
        template_name: '',
      },
      active_template: {
        body: {
          content: '',
          examples: [],
        },
        header: {
          type: 'none',
          content: '',
          example: { data: '', base64: '' },
        },
        footer: {
          content: '',
          examples: [],
        },
        buttons: {
          parent_type: 'none',
          content: [],
          examples: [],
        },
        carousel: [
          {
            body: {
              content: '',
              examples: [],
            },
            header: {
              type: 'none',
              content: 'image',
              file: null,
              example: { data: '', base64: '' },
            },
            footer: {
              content: '',
              examples: [],
            },
            buttons: {
              parent_type: 'call_to_action',
              content: [{
                type: 'url',
                url_type: '',
                text: '',
                url: '',
                phone_number: undefined,
                country_code: undefined,
              }],
              examples: [],
            },
          },
        ],
      },
      example_template: {
        body: {
          content: '',
          examples: [],
        },
        header: {
          type: 'none',
          content: '',
          example: { data: '', base64: '' },
        },
        footer: {
          content: '',
          examples: [],
        },
        buttons: {
          parent_type: 'none',
          content: [],
          examples: [],
        },
      },
      template_form: [],
      otp_delivery_options: [
        // {
        //   title: 'Auto-fill',
        //   description: 'Recommended as the easiest option for your customers. The code is sent to your app when a customer taps the button. A copy code message will be sent when auto-fill isn\'t possible.',
        //   value: 'one_tap',
        // },
        {
          title: 'Copy Code',
          description: 'Basic authentication with quick setup. Your customers copy and paste the code into your app.',
          value: 'copy_code',
        },
      ],
      languages_list: language,
      header_category: [
        {
          type: 'none',
          label: 'None',
        },
        {
          type: 'text',
          label: 'Text',
        },
        {
          type: 'media',
          label: 'Media',
        },
        {
          type: 'carousel',
          label: 'Carousel',
        },
      ],
      header_media_type: [
        {
          type: 'image',
          label: 'Image',
          icon: 'image',
        },
        {
          type: 'video',
          label: 'Video',
          icon: 'video',
        },
        {
          type: 'document',
          label: 'Document',
          icon: 'file-alt',
        },
      ],
      footer_category: [
        {
          type: 'none',
          label: 'None',
          group: ['none'],
        },
        {
          type: 'call_to_action',
          label: 'Call To Action',
          group: ['url', 'phone_number', 'copy_code', 'flow'],
        },
        {
          type: 'quick_reply',
          label: 'Quick Reply',
          group: ['quick_reply'],
        },
      ],
      call_to_action_category: [
        {
          type: 'url',
          label: 'Visit Website',
          max_length: 2,
        },
        {
          type: 'phone_number',
          label: 'Call Phone Number',
          max_length: 1,
        },
        {
          type: 'copy_code',
          label: 'Copy Coupon Code',
          max_length: 1,
        },
        // {
        //   type: 'flow',
        //   label: 'Flow',
        //   max_length: 1,
        // },
      ],
      url_type: [
        {
          type: 'static',
          label: 'Static',
        },
        {
          type: 'dynamic',
          label: 'Dynamic',
        },
      ],
      country_code: country_code.map((v) => {
        v.label_str = `(${v.value}) ${v.label}`;
        return v;
      }),
      addMoreLanguage: '',
      whiteListExtensionHeaderMedia: {
        image: ['.jpg', '.png'],
        video: ['.mp4'],
        document: ['.pdf'],
      },
      division_list: [],
      button_list: [],
      last_index_emoji: null,
      wa_auth_wording,
      flowList: [],
      flowID: '',
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    activeChannel() {
      return this.$store.state.channels.activeChannel;
    },
    isValidStep1() {
      return this.form1.category !== '' && this.form1.template_name !== '' && this.temp.selected_language.length > 0 && this.temp.groups_id.length > 0;
    },
    isMaxVariableHeader() {
      if (!this.active_template.header.content) return false;
      const match_regex = this.active_template.header.content.match(/({{\d{1,}}})/ig);
      return this.active_template.header.content && match_regex && match_regex.length > 0;
    },
    previewHeaderContent() {
      if (this.active_template.header.type === 'text') {
        if (this.active_template.header.example.data) {
          return this.active_template.header.content.replace('{{1}}', this.active_template.header.example.data);
        }
        return this.active_template.header.content;
      }
      return '';
    },
    previewFooterContent() {
      return this.active_template.footer.content;
    },
    previewContent() {
      let string = this.active_template.body.content;
      this.active_template.body.examples.forEach((v) => {
        if (v.text) {
          const replaceRegex = new RegExp(escapeRegExp(v.replacement), 'ig');
          string = string.replace(replaceRegex, v.text);
        }
      });
      // if (this.active_template.category === 'authentication') {
      //   string = this.wa_auth_wording[this.active_template.language].body;
      // }
      const htmlFormat = [
        { symbol: '*', tag: 'b' },
        { symbol: '_', tag: 'em' },
        { symbol: '~', tag: 'del' },
        { symbol: '```', tag: 'tt' },
        { symbol: '`', tag: 'mark' },
      ];

      htmlFormat.forEach(({ symbol, tag }) => {
        if (!string) return;
        const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
        const match = string.match(regex);
        if (!match) return;
        match.forEach((m) => {
          let formatted = m;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < 2; i++) {
            formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
          }
          string = string.replace(m, formatted);
        });
      });
      return string;
    },
    bodyHasParam() {
      if (!this.active_template.body) {
        return false;
      }
      const matched = this.active_template.body.content.match(/({{\d{1,}}})/ig);
      if (!matched) {
        return false;
      }
      return matched && matched.length ? uniq(matched) : [];
    },
    headerHasParamOrMedia() {
      if (!this.active_template.header) {
        return false;
      }
      const header_media = this.active_template.header.type === 'media';
      const header_carousel = this.active_template.header.type === 'carousel' && this.active_template.header.content ? this.active_template.header.content.match(/({{\d{1,}}})/ig) : false;
      const header_has_param = this.active_template.header.type === 'text' && this.active_template.header.content ? this.active_template.header.content.match(/({{\d{1,}}})/ig) : false;
      return header_has_param || header_media || header_carousel;
    },
    buttonHasDynamic() {
      if (!this.active_template.buttons) {
        return false;
      }
      if (this.active_template.category === 'authentication') {
        return true;
      }
      let has_dynamic = false;
      const url_btn = this.active_template.buttons.content.filter((v) => v.type === 'url' && v.url_type === 'dynamic');
      if (url_btn.length > 0) {
        has_dynamic = true;
      }
      return has_dynamic;
    },
    isNeedSample() {
      return this.bodyHasParam || this.headerHasParamOrMedia || this.buttonHasDynamic;
    },
    isInvalidExample() {
      if (!this.example_template.body) {
        return false;
      }
      if (this.buttonHasDynamic && !this.example_template.buttons.examples.filter((v) => v).length) {
        return true;
      }
      const empty_body_example = this.example_template.body.examples.find((v) => !v.text);
      return !isEmpty(empty_body_example);
    },
    isCompleteSample() {
      if (!this.active_template.body) {
        return false;
      }
      if (this.bodyHasParam) {
        if (this.active_template.body && this.active_template.body.examples.length === 0) {
          return false;
        }
        if (this.bodyHasParam.length > 0 && this.bodyHasParam.length !== this.active_template.body.examples.length) {
          return false;
        }
      }
      if (this.headerHasParamOrMedia && (isEmpty(this.active_template.header.example.data))) {
        return false;
      }
      if (!this.headerHasParamOrMedia && this.active_template.header.example.data) {
        return false;
      }
      if (this.buttonHasDynamic && !this.active_template.buttons.examples.filter((v) => v).length) {
        return false;
      }
      if (!this.cardSampleIsComplete) {
        return false;
      }
      const button_example = this.active_template.buttons.content.filter((item) => item.type === 'url' && item.url_type === 'dynamic');
      if (button_example.length > 0) {
        if (this.active_template.buttons.examples.filter((v) => v).length !== button_example.length) {
          return false;
        }
      }
      const empty_body_example = this.active_template.body.examples.find((v) => !v.text);
      return isEmpty(empty_body_example);
    },
    cardSampleIsComplete() {
      const incomplete = [];
      if (this.active_template.header.type === 'carousel') {
        for (let i = 0; i < this.active_template.carousel.length; i++) {
          if (!this.isCompleteSampleCard(i)) {
            incomplete.push(i);
          }
        }
      }
      return !incomplete.length;
    },
  },
  mounted() {
    // dummy
    // this.form1.category = 'ticket_update';
    // this.form1.template_name = 'test_template';
    // this.temp.selected_language = ['id'];

    // this.nextStep(1);
    // this.active_template = this.template_form[0];
    this.getListDivisions();
  },
  methods: {
    checkComplete() {
      if (this.active_template.header.type === 'carousel') {
        for (let i = 0; i < this.active_template.carousel.length; i++) {
          if (!this.isCompleteSampleCard(i)) {
            return true;
          }
        }
      }
      return false;
    },
    isCompleteSampleCard(key) {
      if (!this.active_template.carousel[key].body.content) {
        return false;
      }
      const bodyParam = this.active_template.carousel[key].body.content.match(/({{\d{1,}}})/ig);
      if (bodyParam) {
        if (this.active_template.carousel[key].body && this.active_template.carousel[key].body.examples.length === 0) {
          return false;
        }
        if (bodyParam.length > 0 && bodyParam.length !== this.active_template.carousel[key].body.examples.length) {
          return false;
        }
      }
      if (!this.active_template.carousel[key].header.example.data || !this.active_template.carousel[key].header.example.base64) {
        return false;
      }
      if (this.active_template.carousel[key].buttons.content.filter((v) => !v.text).length > 0) {
        return false;
      }
      const check_url = this.active_template.carousel[key].buttons.content.filter((v) => v.type === 'url').length;
      if (check_url > 0) {
        if (this.active_template.carousel[key].buttons.content.filter((v) => v.url_type).length < check_url) {
          return false;
        }
        if (this.active_template.carousel[key].buttons.content.filter((v) => v.url).length < check_url) {
          return false;
        }
      }
      const check_url_dynamic = this.active_template.carousel[key].buttons.content.filter((v) => v.type === 'url' && v.url_type === 'dynamic').length;
      const count_url_dynamic = this.active_template.carousel[key].buttons.examples.filter((v) => v !== '').length;
      if (count_url_dynamic !== check_url_dynamic) {
        return false;
      }
      const check_body_dynamic = this.active_template.carousel[key].body.content.match(/({{\d{1,}}})/ig)?.length || 0;
      const count_body_dynamic = this.active_template.carousel[key].body.examples.filter((v1) => v1.text).length;
      if (count_body_dynamic !== check_body_dynamic) {
        return false;
      }
      const check_phone = this.active_template.carousel[key].buttons.content.filter((v) => v.type === 'phone_number').length;
      if (check_phone > 0) {
        if (this.active_template.carousel[key].buttons.content.filter((v) => v.country_code).length < check_phone) {
          return false;
        }
        if (this.active_template.carousel[key].buttons.content.filter((v) => v.phone_number).length < check_phone) {
          return false;
        }
      }
      const empty_body_example = this.active_template.carousel[key].body.examples.filter((v) => !v.text);
      if (empty_body_example > 0) {
        return false;
      }
      return true;
      // return isEmpty(empty_body_example);
    },
    previewContentCarousel(key) {
      let string = this.active_template.carousel[key].body.content;
      this.active_template.carousel[key].body.examples.forEach((v) => {
        if (v.text) {
          const replaceRegex = new RegExp(escapeRegExp(v.replacement), 'ig');
          string = string.replace(replaceRegex, v.text);
        }
      });
      // if (this.active_template.category === 'authentication') {
      //   string = this.wa_auth_wording[this.active_template.language].body;
      // }
      const htmlFormat = [
        { symbol: '*', tag: 'b' },
        { symbol: '_', tag: 'em' },
        { symbol: '~', tag: 'del' },
        { symbol: '```', tag: 'tt' },
        { symbol: '`', tag: 'mark' },
      ];

      htmlFormat.forEach(({ symbol, tag }) => {
        if (!string) return;
        const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
        const match = string.match(regex);
        if (!match) return;
        match.forEach((m) => {
          let formatted = m;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < 2; i++) {
            formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
          }
          string = string.replace(m, formatted);
        });
      });
      return string;
    },
    previewFooterContentCarousel(key) {
      return this.active_template.carousel[key].footer.content;
    },
    checkUrl(idx) {
      const regex = RegExp('(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$', 'i');
      // if (!this.active_template.buttons.content[idx].url.match(regex)) {
      //   return this.active_template.buttons.content[idx].url.match(regex);
      // }
      let result = true;
      if (this.active_template.buttons.content[idx].url !== '') {
        result = this.active_template.buttons.content[idx].url.match(regex);
      }
      return result;
    },
    checkContent(item) {
      const content = this.active_template.buttons.content.filter((v) => item.group.includes(v.type)).length;
      return content > 0 || this.active_template.buttons.content.length >= 10;
    },
    checkFirstButton(type) {
      const content = this.footer_category.find((v) => v.group.includes(type));
      return content.type;
    },
    buttonList() {
      let content = [];
      if (this.active_template.buttons.content.length > 3) {
        content = this.active_template.buttons.content.filter((v) => this.checkFirstButton(v.type) === this.checkFirstButton(this.active_template.buttons.content[0].type)).slice(0, 2);
        if (content.length === 2) {
          this.button_list = content;
        } else {
          this.button_list[0] = this.active_template.buttons.content[0];
          this.button_list[1] = this.active_template.buttons.content[1];
        }
      }
    },
    showAllButtons() {
      this.isShowModal.allButton = !this.isShowModal.allButton;
    },
    setSelectedCategory(item) {
      this.form1.category = item.key;
    },
    nextStep(fromStep) {
      this.template_form = this.temp.selected_language.map((v) => {
        const obj = {
          template_name: this.form1.template_name,
          category: this.form1.category,
          language_str: this.languages_list.find((lg) => lg.value === v).label,
          language: v,
          body: {
            content: '',
            examples: [],
          },
          header: {
            type: 'none',
            content: '',
            example: { data: '', base64: '' },
          },
          footer: {
            content: '',
            examples: [],
          },
          buttons: {
            parent_type: 'none',
            content: [],
            examples: [],
          },
          carousel: [
            {
              body: {
                content: '',
                examples: [],
              },
              header: {
                type: 'none',
                content: 'image',
                file: null,
                example: { data: '', base64: '' },
              },
              footer: {
                content: '',
                examples: [],
              },
              buttons: {
                parent_type: 'call_to_action',
                content: [{
                  type: 'url',
                  url_type: '',
                  text: '',
                  url: '',
                  phone_number: undefined,
                  country_code: undefined,
                }],
                examples: [],
              },
            },
          ],
          otp_delivery_type: 'copy_code',
          otp_button_text: '',
          otp_autofill_text: '',
          otp_package_name: '',
          otp_signature_hash: '',
          otp_add_security_wording: false,
          otp_add_expiry_wording: false,
          otp_expires_in: 5,
        };
        if (this.form1.category.toLowerCase() === 'authentication') {
          obj.body.content = this.wa_auth_wording[v].body;
          obj.body.examples = [
            {
              replacement: '{{1}}',
              text: '123456',
            },
          ];
          obj.buttons = {
            parent_type: 'call_to_action',
            content: [
              {
                type: 'otp',
                url_type: 'dynamic',
                text: obj.otp_button_text,
                url: 'https://www.whatsapp.com/otp/code/?otp_type=COPY_CODE&code=otp{{1}}',
              },
            ],
            examples: [
              'https://www.whatsapp.com/otp/code/?otp_type=COPY_CODE&code=otp123456',
            ],
          };
        }
        return obj;
      });
      this.step = fromStep + 1;
      this.active_template = this.template_form[0];
    },
    setActiveTemplate(lang) {
      this.template_form = this.template_form.map((v) => {
        if (v.language === this.active_template.language) {
          return this.active_template;
        }
        return v;
      });
      this.active_template = this.template_form.find((v) => v.language === lang);
    },
    handlerInputTemplateName(event, target) {
      if (target === 'edit_template_name') {
        this.temp.template_name = this.temp.template_name.replace(/\s/g, '_');
        this.temp.template_name = this.temp.template_name.toLowerCase();
      } else if (target === 'create_template_name') {
        this.form1.template_name = this.form1.template_name.replace(/\s/g, '_');
        this.form1.template_name = this.form1.template_name.toLowerCase();
      }
    },
    showModalEditTemplateName() {
      this.temp.template_name = this.form1.template_name;
      this.isShowModal.editTemplateName = true;
    },
    onSubmitEditTemplateName() {
      this.form1.template_name = this.temp.template_name;
      this.temp.template_name = '';
      this.isShowModal.editTemplateName = false;
    },
    closeModalEditTemplate() {
      this.temp.template_name = '';
      this.isShowModal.editTemplateName = false;
    },
    addVariableBody() {
      const total_variable = this.active_template.body.content.match(/({{\d{1,}}})/ig);
      this.active_template.body.content += `${this.active_template.body.content.endsWith(' ') ? '' : ' '}{{${total_variable ? total_variable.length + 1 : 1}}}`;
      this.$refs.inputBodyContent.focus();
    },
    handlerInputBodyContent(event) {
      const { keyCode, shiftKey } = event;
      if (keyCode === 219 && shiftKey) {
        if (this.active_template.body.content.endsWith('{')) {
          const total_variable = this.active_template.body.content.match(/({{\d{1,}}})/ig);
          this.active_template.body.content += `{${total_variable ? total_variable.length + 1 : 1}}}`;
          this.$refs.inputBodyContent.focus();
          event.preventDefault();
        }
      }
    },
    handlerChangeButtonsParent(value) {
      if (value === 'none') {
        this.active_template.buttons.content = [];
        this.active_template.buttons.examples = [];
      } else if (value === 'quick_reply') {
        const idx = this.active_template.buttons.content.findLastIndex((v) => v.type === 'quick_reply');
        this.active_template.buttons.content.splice(idx + 1, 0, {
          type: 'quick_reply',
          text: '',
        });
        this.active_template.buttons.examples.splice(idx + 1, 0, null);
      } else if (value === 'call_to_action') {
        const idx = this.active_template.buttons.content.findLastIndex((v) => v.type !== 'quick_reply');
        this.active_template.buttons.content.splice(idx + 1, 0, {
          type: 'url',
          url_type: 'static',
          text: '',
          url: '',
          phone_number: undefined,
          country_code: undefined,
          flow_id: undefined,
          flow_action: undefined,
          navigate_screen: undefined,
        });
        this.active_template.buttons.examples.splice(idx + 1, 0, null);
      }
      this.buttonList();
    },
    addButtonQuickReply() {
      const idx = this.active_template.buttons.content.findLastIndex((v) => v.type === 'quick_reply');
      this.active_template.buttons.content.splice(idx + 1, 0, {
        type: 'quick_reply',
        text: '',
      });
      this.active_template.buttons.examples.splice(idx + 1, 0, null);
      this.buttonList();
    },
    handlerRemoveQuickReply(index) {
      this.active_template.buttons.content.splice(index, 1);
      this.active_template.buttons.examples.splice(index, 1);
      if (this.active_template.buttons.content.length === 0) {
        this.active_template.buttons.parent_type = 'none';
      } else if (this.active_template.buttons.content.filter((v) => v.type === 'quick_reply').length === 0) {
        this.active_template.buttons.parent_type = 'call_to_action';
      }
      this.buttonList();
    },
    addButtonCTA() {
      // const current_button = this.active_template.buttons.content[0];
      const idx = this.active_template.buttons.content.findLastIndex((v) => v.type !== 'quick_reply');
      const form = {
        copy_code: {
          type: 'copy_code',
          text: '',
        },
        url: {
          type: 'url',
          url_type: 'static',
          text: '',
          url: '',
        },
        phone_number: {
          type: 'phone_number',
          text: '',
          phone_number: '',
          country_code: '+62',
        },
        flow: {
          type: 'flow',
          text: '',
          flow_id: '',
          flow_action: '',
          navigate_screen: '',
        },
      };
      let push = 0;
      this.call_to_action_category.forEach((v) => {
        const len = this.active_template.buttons.content.filter((x) => x.type === v.type).length;
        if (len < v.max_length && push === 0) {
          this.active_template.buttons.content.splice(idx + 1, 0, form[v.type]);
          this.active_template.buttons.examples.splice(idx + 1, 0, null);
          push = 1;
        }
      });
      this.buttonList();
    },
    handlerRemoveCTA(index) {
      if (this.active_template.buttons.content[index].type === 'flow') {
        this.flowID = '';
      }
      this.active_template.buttons.content.splice(index, 1);
      this.active_template.buttons.examples.splice(index, 1);
      if (this.active_template.buttons.content.length === 0) {
        this.active_template.buttons.parent_type = 'none';
      } else if (this.active_template.buttons.content.filter((v) => v.type !== 'quick_reply').length === 0) {
        this.active_template.buttons.parent_type = 'quick_reply';
      }
      this.buttonList();
    },
    handlerRemoveLanguageContent(selected_template) {
      const index = this.template_form.findIndex((v) => v.language === selected_template.language);
      this.active_template = this.template_form[(index > 0 ? index - 1 : index + 1)];
      this.temp.selected_language.splice(this.temp.selected_language.findIndex((v) => v === selected_template.language), 1);
      this.template_form.splice(index, 1);
    },
    handlerRemoveCardContent(key) {
      this.active_template.carousel.splice(key, 1);
    },
    handlerAddMoreLanguage(value) {
      const obj = {
        template_name: this.form1.template_name,
        category: this.form1.category,
        language_str: this.languages_list.find((lg) => lg.value === value).label,
        language: value,
        body: {
          content: '',
          examples: [],
        },
        header: {
          type: 'none',
          content: '',
          example: { data: '' },
        },
        footer: {
          type: '',
          examples: [],
        },
        buttons: {
          parent_type: 'none',
          content: [],
          examples: [],
        },
        carousel: [
          {
            body: {
              content: '',
              examples: [],
            },
            header: {
              type: 'none',
              content: 'image',
              file: null,
              example: { data: '', base64: '' },
            },
            footer: {
              content: '',
              examples: [],
            },
            buttons: {
              parent_type: 'call_to_action',
              content: [{
                type: 'url',
                url_type: '',
                text: '',
                url: '',
                phone_number: undefined,
                country_code: undefined,
              }],
              examples: [],
            },
          },
        ],
      };
      this.temp.selected_language.push(value);
      this.template_form.push(obj);
      this.addMoreLanguage = '';
    },
    addVariableHeader() {
      this.active_template.header.content += '{{1}}';
    },
    handlerInputHeaderContent(event) {
      const { keyCode, shiftKey } = event;
      if (keyCode === 219 && shiftKey) {
        if (this.active_template.header.content.endsWith('{')) {
          if (!this.active_template.header.content) {
            this.active_template.header.content = '';
          }
          const total_variable = this.active_template.header.content.match(/({{\d{1,}}})/ig);
          if (!total_variable || !total_variable.length) {
            this.active_template.header.content += `{${total_variable ? total_variable.length + 1 : 1}}}`;
            this.$refs.inputHeaderContent.focus();
            event.preventDefault();
          }
        }
      }
    },
    async submitTemplate() {
      this.loading.submit = true;
      const response = await notifApi.submitRequestTemplate({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.activeChannel._id,
        templates: this.template_form,
        groups_id: this.temp.groups_id,
      });
      const { error } = response;
      this.loading.submit = false;
      if (error) {
        await popupErrorMessages(response);
      } else {
        this.$message({
          message: this.$t('templates.success.request_template'),
          type: 'success',
        });
        this.$router.push({
          name: 'Broadcast Templates WhatsApp',
        });
      }
    },
    addSampleContent() {
      this.active_template.body.examples = [];
      if (this.bodyHasParam) {
        this.bodyHasParam.forEach((v) => {
          if (!this.active_template.body.examples.find((vk) => vk.replacement === v)) {
            this.active_template.body.examples.push({ replacement: v, text: '' });
          }
        });
        if (this.bodyHasParam && this.active_template.body.examples.length > this.bodyHasParam.length) {
          this.active_template.body.examples.splice(this.bodyHasParam.length);
        }
      }
      if (!this.headerHasParamOrMedia) {
        this.active_template.header.example.data = '';
      }
      this.example_template = cloneDeep(this.active_template);
      this.isShowModal.addSample = true;
    },
    showCarouselCard(key) {
      this.active_card = key;
      this.isShowModal.carouselCard = true;
    },
    addCarouselCard() {
      const new_template = {
        body: {
          content: '',
          examples: [],
        },
        header: {
          type: 'none',
          content: 'image',
          file: null,
          example: { data: '', base64: '' },
        },
        footer: {
          content: '',
          examples: [],
        },
        buttons: {
          parent_type: 'call_to_action',
          content: [{
            type: 'url',
            url_type: '',
            text: '',
            url: '',
            phone_number: undefined,
            country_code: undefined,
          }],
          examples: [],
        },
      };
      console.log('carousel length', this.active_template.carousel.length);
      if (this.active_template.carousel.length) {
        const sample = cloneDeep(this.active_template.carousel[0]);
        console.log('sample', sample);
        new_template.buttons.content = sample.buttons.content.map((v) => {
          v.url_type = '';
          v.text = '';
          v.url = '';
          v.phone_number = undefined;
          v.country_code = undefined;
          return v;
        });
        console.log('new template', new_template);
      }
      const carousel_content = this.active_template.carousel;
      carousel_content.push(new_template);
      this.active_template.carousel = carousel_content;
    },
    submitChangeTemplateName() {
      this.active_template.template_name = this.temp.template_name;
      this.template_form = this.template_form.map((v) => {
        v.template_name = this.temp.template_name;
        return v;
      });
      this.form1.template_name = this.temp.template_name;
      this.isShowModal.editTemplateName = false;
    },
    submitExample() {
      this.active_template.body.examples = this.example_template.body.examples;
      this.active_template.header.example = this.example_template.header.example;
      this.active_template.buttons.examples = this.example_template.buttons.examples;
      this.isShowModal.addSample = false;
    },
    handlerChangeHeaderType() {
      this.active_template.header.example = {
        data: '',
        base64: '',
      };
      this.active_template.header.content = '';
    },
    async handlerFileHeaderMedia() {
      this.loading.upload_sample_media = true;
      if (this.example_template.header.file) {
        const formData = new FormData();
        formData.append('file', this.example_template.header.file);
        const response = await notifApi.uploadTemplateMedia(formData, this.activeWorkspace._id, this.activeChannel._id);
        await popupErrorMessages(response);
        this.example_template.header.example.data = response.data.h;
        this.example_template.header.example.base64 = await base64File(this.example_template.header.file);
      }
      this.loading.upload_sample_media = false;
    },
    async getListDivisions() {
      this.loading.table = true;
      const options = {
        workspace_id: this.activeWorkspace._id,
        channel_type: 'whatsapp',
      };
      const response = await divisions.listForTemplates(options);
      this.loading.table = false;
      await popupErrorMessages(response);
      const { data } = response;
      this.division_list = data.rows.map((v) => {
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        return v;
      });
      this.total = data.count;
    },
    addEmoji(emoji) {
      const pos = this.last_index_emoji !== null ? this.last_index_emoji : this.$refs.inputBodyContent.$refs.textarea.selectionStart;
      if (this.last_index_emoji === null) this.last_index_emoji = pos;
      const start_str = this.active_template.body.content.substring(0, pos);
      const end_str = this.active_template.body.content.substring(pos, this.active_template.body.content.length);
      this.active_template.body.content = [start_str, emoji.native, end_str].join('');
    },
    handlerOtpTypeRadio(type) {
      this.active_template.otp_delivery_type = type;
    },
    checkedSecurityChanged(value, lang) {
      if (value) {
        this.active_template.body.content += ` ${this.wa_auth_wording[lang].security_text}`;
      } else {
        this.active_template.body.content = this.active_template.body.content.replace(` ${this.wa_auth_wording[lang].security_text}`, '');
      }
    },
    handlerChangeOtpExpires() {
      this.checkedExpiryChanged(this.active_template.otp_add_expiry_wording, this.active_template.language);
    },
    checkedExpiryChanged(value, lang) {
      if (value) {
        this.active_template.footer = {
          content: this.wa_auth_wording[lang].footer_text.replace('{{expiry_time}}', this.active_template.otp_expires_in),
        };
      } else {
        this.active_template.footer = {
          type: '',
          examples: [],
        };
      }
    },
    async handlerSearchFlow(keyword = '') {
      const options = {
        page: 1,
        search: keyword,
        published: true,
      };
      this.flowList = [];
      this.loading.get_flows = true;
      await flowsAPI.get({
        workspace_id: this.activeWorkspace._id,
        options,
      }).then(async (res) => {
        this.flowList = res.data.rows;
        this.loading.get_flows = false;
      }).catch((err) => {
        console.log(err);
        this.loading.get_flows = false;
      });
    },
    async handlerDetailFlow() {
      await flowsAPI.detail({
        workspace_id: this.activeWorkspace._id,
        flow_id: this.flowID,
      }).then(async (res) => {
        await popupErrorMessages(res);
        const flow_idx = this.active_template.buttons.content.findIndex((v) => v.type === 'flow');
        this.active_template.buttons.content[flow_idx].flow_id = res.data.flow.provider_flow_id;
        this.active_template.buttons.content[flow_idx].flow_action = 'navigate';
        if (!isEmpty(res.data.flow.json_data.screens)) {
          this.active_template.buttons.content[flow_idx].navigate_screen = res.data.flow.json_data.screens[0].id;
        }
      });
    },
  },
};
</script>
<style>
/* .fade-enter-active {
  transition: top 0.5s ease-in;
}
.fade-leave-active {
  transition: all 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
} */

.screen {
  text-align: left;
}
.screen-container {
  height: 100%;
}

/* Status Bar */

.status-bar {
  height: 25px;
  background: #004e45;
  color: #fff;
  font-size: 14px;
  padding: 0 8px;
}

.status-bar:after {
  content: "";
  display: table;
  clear: both;
}

.status-bar div {
  float: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 0 0 8px;
  font-weight: 600;
}

/* Chat */

.chat {
  height: calc(100% - 69px);
}

.chat-container {
  height: 100%;
}

/* User Bar */

.user-bar {
  height: 55px;
  background: #005e54;
  color: #fff;
  padding: 0 8px;
  font-size: 24px;
  position: relative;
  z-index: 1;
}

.user-bar:after {
  content: "";
  display: table;
  clear: both;
}

.user-bar div {
  float: left;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.user-bar .actions {
  float: right;
  margin: 0 0 0 20px;
}

.user-bar .actions.more {
  margin: 0 12px 0 32px;
}

.user-bar .actions.attachment {
  margin: 0 0 0 30px;
}

.user-bar .actions.attachment i {
  display: block;
  transform: rotate(-45deg);
}

.user-bar .avatar {
  margin: 0 0 0 5px;
  width: 36px;
  height: 36px;
}

.user-bar .avatar img {
  border-radius: 50%;
  /* box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1); */
  display: block;
  width: 100%;
}

.user-bar .name {
  font-size: 17px;
  font-weight: 600;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
  margin: 0 0 0 8px;
  overflow: hidden;
  white-space: nowrap;
  width: 110px;
}

.user-bar .status {
  display: block;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
}

/* Conversation */

.conversation {
  height: calc(100% - 12px);
  position: relative;
  background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat;
  z-index: 0;
}

.conversation ::-webkit-scrollbar {
  transition: all .5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  height: calc(100% - 68px);
  /* box-shadow: inset 0 10px 10px -10px #000000; */
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
}

.conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

/* Messages */

.preview-message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  /* max-width: 85%; */
  word-wrap: break-word;
  /* z-index: -1; */
}

.preview-message-carousel {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  /* max-width: 85%; */
  word-wrap: break-word;
  /* z-index: -1; */
}

.preview-message-carousel {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  /* max-width: 85%; */
  word-wrap: break-word;
  z-index: -1;
}

.preview-message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.button-area {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px 0px;
  position: relative;
  word-wrap: break-word;
  z-index: -1;
  border-radius: 5px;
  float: left;
}

.reply-button {
  color: #5591EC;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  word-wrap: break-word;
  z-index: -1;
  background: #fff;
  border-radius: 5px;
  float: left;
  text-align: center;
}

.cta-area {
  border-top: 1px solid #dadde1;
  /* margin: 0 7px 0 9px; */
  margin-top: 8px;
  float: right;
  background: #fff;
}

.cta-button {
  color: #00a5f4;
  font-size: 12px;
  height: 24px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  text-align: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  word-wrap: break-word;
  margin-top: 5%;
}

.header-media-area {
  padding: 3px 3px 0 3px;
}

.header-media-content {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  border-radius: 4px;
  box-sizing: border-box;
}

.header-media-content::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}

.header-media-image {
  background-image: url('https://media.wappin.chat/template-header-image.png');
}

.header-media-filled {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 4px;
  box-sizing: border-box;
  /* width: 240px; */
}

.header-media-filled::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: .5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
          transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
          transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
          transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
          transform: perspective(800px) rotateY(-179.9deg);
}

.preview-message:first-child {
  margin: 16px 0 8px;
}

.preview-message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.preview-message.received .metadata {
  padding: 0 0 0 16px;
}

.preview-message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.preview-message.received.carousel-message {
  border-radius: 5px;
}
.preview-message.received.carousel-message img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.preview-message.received.carousel-message:after {
  /* border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0; */
  left: 10px;
}

.preview-message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.preview-message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.preview-message-carousel:first-child {
  margin: 16px 0 8px;
}

.preview-message-carousel.received {
  background: #fff;
  border-radius: 5px 5px 5px 5px;
  float: left;
}

.preview-message-carousel.received .metadata {
  padding: 0 0 0 16px;
}

/* .preview-message-carousel.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  right: 10px;
  content: '';
} */

.preview-message-carousel.received.carousel-message {
  border-radius: 5px;
}
.preview-message-carousel.received.carousel-message img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.preview-message-carousel.received.carousel-message:after {
  /* border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0; */
  left: 10px;
}

.preview-message-carousel.sent {
  background: #e1ffc7;
  border-radius: 5px 5px 5px 5px;
  float: right;
}

.preview-message-carousel:first-child {
  margin: 16px 0 8px;
}

.preview-message-carousel.received {
  background: #fff;
  border-radius: 5px 5px 5px 5px;
  float: left;
}

.preview-message-carousel.received .metadata {
  padding: 0 0 0 16px;
}

/* .preview-message-carousel.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  right: 10px;
  content: '';
} */

.preview-message-carousel.received.carousel-message {
  border-radius: 5px;
}
.preview-message-carousel.received.carousel-message img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.preview-message-carousel.received.carousel-message:after {
  /* border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0; */
  left: 10px;
}

.preview-message-carousel.sent {
  background: #e1ffc7;
  border-radius: 5px 5px 5px 5px;
  float: right;
}

.preview-button {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  margin-bottom: 10px;
  /* position: absolute; */
  /* border: solid 1px #97704F; */
  /* margin: 0px; */
  /* margin: 8px 0; */
  word-wrap: break-word;
  /* z-index: -1; */
}

.preview-button.bottom {
  bottom: 0;
  background: #fff;
  border-radius: 5px 5px 5px 5px;
  float: left;
}
/* Compose */

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 50px;
  width: 100%;
  z-index: 2;
}

.conversation-compose div,
.conversation-compose input {
  background: #fff;
  height: 100%;
}

.conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 5px 0 0 5px;
  flex: 0 0 auto;
  margin-left: 8px;
  width: 48px;
}

.conversation-compose .input-msg {
  border: 0;
  flex: 1 1 auto;
  font-size: 16px;
  margin: 0;
  outline: none;
  min-width: 50px;
}

.conversation-compose .photo {
  flex: 0 0 auto;
  border-radius: 0 0 5px 0;
  text-align: center;
  position: relative;
  width: 48px;
}

.conversation-compose .photo:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  top: 0;
  right: -10px;
}

.conversation-compose .photo i {
  display: block;
  color: #7d8488;
  font-size: 24px;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
}

.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none;
}

.conversation-compose .send .circle {
  background: #008a7c;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation-compose .send .circle i {
  font-size: 24px;
  margin-left: 5px;
}

.preview-footer {
  font-size: 11px;
  color: rgba(0, 0, 0, .45);
  word-wrap: break-word;
  white-space: pre-wrap;
}

.preview-body {
  font-size: 12px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.row-button {
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.col-button {
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgb(0 0 0 / 15%);
  box-sizing: border-box;
  flex-grow: 1;
  margin: 2px 0 0 2px;
  min-width: calc(50% - 2px);
  color: #00a5f4;
  font-size: 14px;
  height: 34px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
}

.swiper-slide {
  display: flex !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
  /* width: 202px !important; */
  /* width: 100% !important; */
  min-width: 204px;
}
.swiper-wrapper {
  display: flex;
  width: 100px !important;
  /* min-width: 300px; */
  /* width: auto; */
}
.swiper-container {
  height: 450px !important;
  width: 100% !important;
}

/* Small Screens */
@media (max-width: 768px) {
  .marvel-device.nexus5 {
    border-radius: 0;
    flex: none;
    padding: 0;
    max-width: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  .marvel-device > .screen .chat {
    visibility: visible;
  }

  .marvel-device {
    visibility: hidden;
  }

  .marvel-device .status-bar {
    display: none;
  }

  .screen-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .conversation {
    height: calc(100vh - 55px);
  }
  .conversation .conversation-container {
    height: calc(100vh - 120px);
  }
}
</style>
